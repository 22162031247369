import React, { useRef } from "react"
import { Link } from "gatsby"
import * as styles from "./CaseStudies.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"

const data = [
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_82fa93a54a.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_Logo_956ab76922.png",
    title: "IT Staff Outsourcing",
    head: "Unified public notice platform for newspapers and their customers.",
    button: "/portfolio/freshprep/",
    button2: "Resource Augmentation",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/column_d320dc796e.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/column_logo_396544fd95.png",
    title: "From Analog to Digital",
    head: "Turning Pen & Paper Processes into Agile SaaS Solutions.",
    button: "/portfolio/column/",
    button2: "Development",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_readeer_902773df83.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_logo_62f45929d7.png",
    title: "Real Time Factory Tracker",
    head: "Where Data Drives: Decisions, Efficiency, Downtime, Quality, Visibility, Competitivenes.",
    button: "/portfolio/globalreader/",
    button2: "AI-Powered Precision",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_d8867da5a9.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_logo_e0308594bb.png",
    title: "A Fintech Card Issuer & Service Provider",
    head: "Modernizing Global Payment Platform",
    button: "/portfolio/nymcard/",
    button2: "Web Application",
  },
]

const TrustInvoZone = ({ strapiData, projectEng }) => {
  const sliderRef = useRef(null)

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 2,
    speed: 500,
    dots: false,
    dots: true,
    dotsClass: "clients-dots",
    arrows: false,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p
            className={`${styles.ser} ${
              projectEng ? styles.projectEngLine : ""
            }`}
          >
            {projectEng ? "Portfolio" : "Case Studies"}
          </p>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>
              {projectEng
                ? "Check Out Our Sensational Portfolio Of Successful Projects"
                : "We’re Not Just About Talks. We Actually Deliver Results"}
            </h2>
            <div className={styles.talkBtn}>
              <Link to={"/portfolio/"} className={styles.btn}>
                See All Case Studies
                <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                  decoding="async"
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              {/* <div className={styles.overlay} /> */}
            </Col>
            <Slider
              {...settings}
              className={styles.SliderWidth}
              ref={sliderRef}
            >
              {data?.map((e, i) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={i}
                  className={styles.portfolios}
                  onMouseEnter={() => sliderRef.current.slickPause()}
                  onMouseLeave={() => sliderRef.current.slickPlay()}
                >
                  <div>
                    <img
                      src={e?.images}
                      alt="client logos"
                      decoding="async"
                      loading="lazy"
                      className={styles.clientImgs}
                    />
                    <div className={styles.overly}>
                      <div className={styles.talkBtn2}>
                        <Link to={"/contact-us/"} className={styles.btn2}>
                          {e?.button2}
                        </Link>
                      </div>
                      <div className={styles.content}>
                        <p>{e?.title}</p>
                        <h3>{e?.head}</h3>
                      </div>

                      <div className={styles.logos}>
                        <hr className={styles.line} />
                        <div className={styles.logoBtn}>
                          <img
                            src={e?.logos}
                            alt="client logos"
                            decoding="async"
                            loading="lazy"
                          />
                          <Link to={e?.button} className={styles.btn2}>
                            <img
                              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                              decoding="async"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
