import React, { useState } from "react"
import * as styles from "./Clients.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import "./Clients.scss"
import LeftArrow from "../../images/softwere-development/left.svg"
import RightArrow from "../../images/softwere-development/right.svg"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={RightArrow}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={LeftArrow}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const TrustInvoZone = ({ strapiData, projectEngRTest }) => {
  const data = [
    {
      name: "Oliver Wolff",
      role: "Kinde Product Manager",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007447_b51a07250b.webp",
      thumbnail2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007451_e17d297a89.webp",
      video:
        "https://www.youtube.com/embed/eGinGdGXdGA?si=RyFeh0vrfexzxpTU&autoplay=1",
    },
    {
      name: "Ryan Carter",
      role: "Shield Republic",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007446_7c2e0849af.webp",
      thumbnail2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007450_f1dcb5c0f3.webp",
      video:
        "https://www.youtube.com/embed/ETvJcozwwzM?si=593q6eg9VhyVIm4B&autoplay=1",
    },
    {
      name: "Rizwan Hanif",
      role: "Xtransform’s CEO",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007448_0aa9e835fe.webp",
      thumbnail2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007452_df2b5eaac7.webp",
      video:
        "https://www.youtube.com/embed/SeBRvwbCuks?si=PBFkID4nfnforjxP&autoplay=1",
    },
    {
      name: "Ron Zabel",
      role: "CEO - Cryptool.io",
      thumbnail:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007449_d071efa13a.webp",
      thumbnail2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007453_6fc5857b9e.webp",
      video: "https://www.youtube.com/embed/aOw4IfvEng4&autoplay=1",
    },
  ]

  const [hoveredButton, setHoveredButton] = useState(null)
  const [hoveredCard, setHoveredCard] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenSheild, setIsModalOpenSheild] = useState(false)
  const [isModalOpenStorageChain, setIsModalOpenStorageChain] = useState(false)

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }
  const handleVideoIconClickSheild = () => {
    setIsModalOpenSheild(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleCloseModalSheild = () => {
    setIsModalOpenSheild(false)
  }
  const handleCloseModalStorageChain = () => {
    setIsModalOpenStorageChain(false)
  }

  // const handleVideoIconClick = () => {
  //   setIsModalOpen(true)
  // }

  // const handleCloseModal = () => {
  //   setIsModalOpen(false)
  // }

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    // speed: 500,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p
            className={`${styles.ser} ${
              projectEngRTest ? styles.projectEng : ""
            }`}
          >
            Testimonials
          </p>
          <h2
            className={`${styles.heading} ${
              projectEngRTest ? styles.projectEngHeading : ""
            }`}
          >
            {projectEngRTest
              ? "Catch The Inspiring Stories Of Our Clients"
              : "Hear The Voice Of Success From Our Worthy Clients"}
          </h2>
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {data?.map((e, i) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={i}
                  onMouseEnter={() => setHoveredCard(i)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <div className={styles.shieldTopBannerImg}>
                    <img
                      className={styles.sheildImgTop}
                      src={hoveredCard === i ? e?.thumbnail2 : e?.thumbnail}
                      alt=""
                    />
                    <div className={styles.roleBtn}>
                      <p className={styles.name}>{e?.name}</p>
                      <p className={styles.role}>{e?.role}</p>
                      <hr />
                      <button
                        href="#"
                        onClick={() => setIsModalOpenStorageChain(true)}
                        className={styles.buton}
                        onMouseEnter={() => setHoveredButton(i)}
                        onMouseLeave={() => setHoveredButton(null)}
                      >
                        <img
                          src={
                            "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_d99dfad8cf.png"
                          }
                          className={styles.icon}
                          decoding="async"
                          loading="lazy"
                          style={{ height: "22px", width: "35px" }}
                        />
                        {hoveredButton === i && (
                          <span className={styles.text}>Play Video</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
      {data?.map((e, i) => (
        <Modal
          show={isModalOpenStorageChain}
          onHide={handleCloseModalStorageChain}
          keyboard={false}
          centered
          size="lg"
          contentClassName={styles.modalContentTestiRevamp}
          key={i}
        >
          <Modal.Body className={styles.modalBodytestiRevamp}>
            <iframe
              width="100%"
              height="315"
              src={e?.video}
              allowFullScreen
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay"
            />
          </Modal.Body>
        </Modal>
      ))}
    </div>
  )
}

export default TrustInvoZone
