// extracted by mini-css-extract-plugin
export var SliderWidth = "CaseStudies-module--SliderWidth--d3055";
export var Trust = "CaseStudies-module--Trust--2b85e";
export var bottomLine = "CaseStudies-module--bottomLine--835e6";
export var btn = "CaseStudies-module--btn--65500";
export var btn2 = "CaseStudies-module--btn2--dd102";
export var clientImgs = "CaseStudies-module--clientImgs--2f4a5";
export var content = "CaseStudies-module--content--9c034";
export var devOpsRefineImages = "CaseStudies-module--devOpsRefineImages--e2fbc";
export var headSearch = "CaseStudies-module--headSearch--7fb96";
export var heading = "CaseStudies-module--heading--20066";
export var logoBtn = "CaseStudies-module--logoBtn--cb964";
export var logos = "CaseStudies-module--logos--a32b7";
export var overly = "CaseStudies-module--overly--3f5c8";
export var portfolios = "CaseStudies-module--portfolios--0fcc0";
export var ser = "CaseStudies-module--ser--5eecb";
export var talkBtn = "CaseStudies-module--talkBtn--55616";
export var talkBtn2 = "CaseStudies-module--talkBtn2--94110";
export var topLine = "CaseStudies-module--topLine--c79a5";