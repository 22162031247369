// extracted by mini-css-extract-plugin
export var ClientsDataPortfoliioRevamp = "Clients-module--ClientsDataPortfoliioRevamp--290da";
export var SliderWidth = "Clients-module--SliderWidth--413d8";
export var Trust = "Clients-module--Trust--5b318";
export var bannerHeadingPortfolioRevamp = "Clients-module--bannerHeadingPortfolioRevamp--c4af0";
export var buton = "Clients-module--buton--4f2f7";
export var devOpsRefineImages = "Clients-module--devOpsRefineImages--8dee5";
export var disabled = "Clients-module--disabled--c59b1";
export var heading = "Clients-module--heading--a304c";
export var icon = "Clients-module--icon--8c4dc";
export var iconContainer = "Clients-module--iconContainer--ffa31";
export var iconContainerLeft = "Clients-module--iconContainerLeft--b618e";
export var modalBodytestiRevamp = "Clients-module--modalBodytestiRevamp--9eaa3";
export var modalContentTestiRevamp = "Clients-module--modalContentTestiRevamp--3ade2";
export var name = "Clients-module--name--0b8b9";
export var overlay = "Clients-module--overlay--0e295";
export var overlay2 = "Clients-module--overlay2--be733";
export var portfolioArrowIcon = "Clients-module--portfolioArrowIcon--e4f5f";
export var portfolioArrowIconCover = "Clients-module--portfolioArrowIconCover--10bda";
export var portfolioArrowRightIconCover = "Clients-module--portfolioArrowRightIconCover--4d0b8";
export var portfoliosLogos = "Clients-module--portfoliosLogos--b532b";
export var role = "Clients-module--role--202e7";
export var roleBtn = "Clients-module--roleBtn--ee8a7";
export var ser = "Clients-module--ser--9280e";
export var sheildImgTop = "Clients-module--sheildImgTop--b1cfc";
export var shieldTopBannerImg = "Clients-module--shieldTopBannerImg--af454";
export var text = "Clients-module--text--b64b8";